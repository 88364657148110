<template>
  <b-card title="Diplomas Schedule">
    <b-tabs>
      <b-tab
        :active="currentRouteName=='schedule-classical-Diplomas'"
        
        @click="$router.push('/schedule-classical-Diplomas')"
      >
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Schedule Classic Diploma</span>
        </template>

      </b-tab>

      <b-tab
        :active="currentRouteName=='schedule-online-Diplomas'"
        @click="$router.push('/schedule-online-Diplomas')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Schedule Online Diploma</span>
        </template>

      </b-tab>
      <b-tab
        :active="currentRouteName=='all-Diplomas-Schedule' || currentRouteName=='Diplomas-schedule-update'"
        @click="$router.push('/all-Diplomas-Schedule')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Schedule  Diplomas List</span>
        </template>

      </b-tab>

    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <ScheduleClassicCourse v-if="route.name === 'schedule-classical-course' " />
      <router-view v-else>
        <component :is="Component" />
      </router-view>
    </router-view>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'

import ScheduleClassicCourse from './ScheduleClassicCourse.vue'
import ScheduleOnlineCourse from './ScheduleOnlineCourse.vue'
import ViewSchedule from './ViewSchedule.vue'

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,

    ScheduleClassicCourse,
    ScheduleOnlineCourse,
    ViewSchedule,
  },
  data() {
    return {

    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
}
</script>
<style>
h4.card-title {
    color: #D69900;
}

</style>
